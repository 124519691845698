@import url(https://fonts.googleapis.com/css2?family=Corben&display=swap);
.navbar {
    width: 100%;
}

.navbar-toggle {
    margin-top: 5px;
    padding: 5px 5px !important;
}

.nav-logo{
    height: 30px;
    padding: 0px;
    margin: 0px;
}

.navitem{
    color: white;
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;
}
.navitem:last-child{
    border-right: 0px solid white;
}
*{
    scroll-behavior: smooth;
}
body{
    overflow-x: hidden;
    font-family: 'Corben', cursive;
    max-width: 100%;
    width: 100%;
}
.backgroundImg{
    width: 100%;
    height: 100%;
    position: fixed;
}

.Logo{
    position: relative;
    margin-left: 36%;
    margin-top: 10%;
    height: 30%;
    width: 30%;
}

.branding{
    position: relative;
    text-align: center;
}

.About{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
    margin-top: 30%;
    text-align: center;
    padding-top: 18%;
    padding-bottom: 18%;
}

.About h1{
    color: white;
    font-size: 50px;
}

.About h6{
    color: white;
    font-size: 30px;
}

.Services{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #8B1955;
    opacity: 0.75;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}

.Services h1{
    color: white;
    font-size: 50px;
}

.Services h6{
    color: white;
    font-size: 30px;
}

.Strategy{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #E67817;
    opacity: 0.75;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}

.Strategy h1{
    color: white;
    font-size: 50px;
}
.Strategy h2{
    color: white;
    font-size: 25px;
    margin-bottom: 5%;
}
.Strategy h6{
    color: white;
    font-size: 19px;
}

.Strategy div{
    align-content: center;
    margin: 0 auto;
}

.Strategy img{
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin-bottom: 5%;
}

.MissionStatement h6{
    margin-top: 10%;    
}

.Whyus{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
    text-align: center;
    padding-top: 8%;
    padding-bottom: 8%;
}

.Whyus h1{
    color: white;
    font-size: 50px;
}

.Whyus h6{
    color: white;
    font-size: 30px;
}

.Whyus hr{
    border-bottom: 1px solid #707070;
    width: 50%;
}

.Whyus hr:last-of-type{
    width: 50%;
}

.Contact{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #E67817;
    opacity: 0.75;
    text-align: center;
    padding-top: 11%;
    padding-bottom: 11%;
}

.Contact h1{
    color: white;
    font-size: 50px;
}

.Contact label{
    color: white;
    float: left;
    font-size: 25px;
}

input[type=file]{
    color: white;
}

.Contact button{
    background-color:#6F1C16;
    width: 20%;
    border: 0px solid white;
}

.Footer{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
    color: white;
}

.Footer h6:last-of-type{
    padding-top: 5%;
    padding-bottom: 0%;
}

.bvm-button{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 8%;
}
