.navbar {
    width: 100%;
}

.navbar-toggle {
    margin-top: 5px;
    padding: 5px 5px !important;
}

.nav-logo{
    height: 30px;
    padding: 0px;
    margin: 0px;
}

.navitem{
    color: white;
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;
}
.navitem:last-child{
    border-right: 0px solid white;
}